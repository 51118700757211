<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="120px">
    <OrganizationSelect v-model="form.organizationId" label="人员归属" required :showAll="false" :isCheck="false" style="width:420px"></OrganizationSelect>
    <!-- <el-form-item label="人员归属" prop="organizationType">
      <el-select class="more-select" size="mini" v-model="form.organizationId" placeholder="请选择所属分支机构" style="width:255px;" :disabled="!editShow">
        <el-option v-for="(item,index) in organizationList" :key="index" :label="item.organizationName" :value="item.id"></el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item label="姓名" prop="sysName">
      <el-input style="width:300px;" placeholder="请输入用户昵称(中英文字母或数字组合,长度小于16位)" v-model="form.sysName" maxlength="16" v-if="editShow"></el-input>
      <div class="content-text" v-else>{{form.sysName}}</div>
    </el-form-item>
    <el-form-item label="手机号码" prop="sysAccount">
      <el-input style="width:300px;" placeholder="请输入电话(7-11位数字)" v-model.number="form.sysAccount" maxlength="11" v-if="editShow"></el-input>
      <div class="content-text" v-else>{{form.sysAccount}}</div>
    </el-form-item>
    <el-form-item label="角色" prop="roleId">
      <el-radio-group v-model="form.roleId" :disabled="!editShow">
        <el-radio style="margin:5px;" v-for="(item,index) in roleList" :key="index" :label="item.roleId" :value="item.roleId">{{item.roleName}}</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-button type="primary" v-debounce="onSubmit" v-if="!editType">立即创建</el-button>
    <el-button type="primary" v-debounce="onSubmit" v-if="editType == 2">立即保存</el-button>
    <el-button @click="dialogClose">关闭窗口</el-button>
  </el-form>
</template>
<script>
import { organizationList, addSysUser, editSysUser, querySysUserById, getRoleAll } from '@/api/setting'
import { getCookies } from '@/utils/utils'


import OrganizationSelect from '@/components/OrganizationSelect.vue'



export default {
  props: ['isAddDialog', 'editId', 'editType', 'pId'],
  components: { OrganizationSelect },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('电话不可为空'));
      }
      if (value.length < 6) {
        return callback(new Error('号码至少7位以上'));
      }
      // let reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
      // let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      // if(reg.test(value)){
      // if(String(value).length > 10){
      //   callback()
      // }else{
      //   callback(new Error('请输入正确手机号码'));
      // }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      organizationList: [],
      roleList: [],
      orgId: getCookies('organizationId'),
      form: {
        organizationId: '',
        roleId: '',                  // 0:管理人员，1：业务人员
        sysName: '',              // 用户名称
        sysAccount: '',           // 手机号
      },
      rules: {
        sysName: [
          { required: true, message: '名称不可为空', trigger: 'blur' },
        ],
        sysAccount: [
          { required: true, message: '联系电话不可为空', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        roleId: [
          { required: true, message: '请选择角色', trigger: 'change' },
        ],
      },

    }
  },
  computed: {
    editShow() {
      return this.editType == 2 || !this.editType
    }
  },
  created() {
    this.form.organizationId = this.pId
    console.log(this.editType, this.editId)
    this.editType && this.editId && this.getDateil();
    this.getOrganizationList();
    this.getRoleAll();
  },
  methods: {
    changeCheck(checkKey) {
      // 去中文正则
      this.form[checkKey] = this.form[checkKey].replace(/[^\w\.\/]/ig, '');
      let data = res.data;
    },
    getRoleAll() {
      let that = this;
      getRoleAll()
        .then((response) => {

          that.roleList = response.data;
          console.log(that.roleList);
        });
    },
    getDateil() {
      let that = this;
      querySysUserById({ id: this.editId })
        .then((response) => {

          if (response.code == 200) {
            that.form['sysId'] = response.data['sysId']
            that.form['roleId'] = Number(response.data['roleId'])
            that.form['sysName'] = response.data['sysName']
            that.form['sysAccount'] = Number(response.data['sysAccount'])
            that.form['organizationId'] = response.data['organizationId']

          } else {
            that.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit(formName = 'form') {

      var that = this;
      let form = JSON.parse(JSON.stringify(that.form));

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (form['sysId']) {
            editSysUser(form)
              .then((response) => {
                if (response.code == 200) {
                  that.$message({
                    message: response.msg,
                    type: 'success'
                  });
                  that.dialogClose();
                } else {
                  that.$message.error({
                    title: '错误',
                    message: response.msg || response.error_msg,
                    duration: 1500
                  });
                }
              })
          } else {
            addSysUser(form)
              .then((response) => {
                if (response.code == 200) {
                  that.dialogClose();
                  that.$message({
                    message: response.msg,
                    type: 'success'
                  });
                } else {
                  that.$message.error({
                    title: '错误',
                    message: response.msg,
                    duration: 1500
                  });
                }
              })
          }

        } else {
          this.$message.error({
            title: '错误',
            message: '请检查重试',
            duration: 1500
          });
          return false;
        }
      })
    },
    getOrganizationList() {
      let that = this;
      let param = {
        orgId: this.orgId,
        // orgId:1,
        isPage: 0,
        pageNum: 1,
        pageSize: 1000000,
      };
      organizationList(param)
        .then((response) => {
          if (response.code == 200) {
            that.organizationList = response.data.records;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }

        })
    },
  },

  beforeDestroy() {
    // this.form = {
    //   organizationName: '',
    //   organizationPhone:'',
    //   organizationIntro: '',
    //   organizationPrincipal: '',
    //   organizationPrincipalPhone: '',
    //   organizationArea: '深圳',
    //   organizationAddress: '',
    // };
  }
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
</style>