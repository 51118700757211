/*
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-03 11:48:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\api\user.js
 */
import { post, fetch, patch, put } from '@/utils/http'
import axios from '@/utils/http'

//登录
export function Login(params) {
  return post('/sysMain/loginSysUser', params);
}

//重置密码
export function resetPwd(params) {
  return post('/sysMain/resetPwd/' + params);
}
//关联分支机构
export function associated(params) {
  return post('/sysMain/sysUserOrgRelation', params);
}


//添加系统用户
export function sysUserAdd(params) {

  return post('/sysMain/addOrEditorSysUser', params);
}
//系统用户修改密码
export function changePwd(params, header) {
  return axios.post('/sysMain/changePwd', params, header);
}

//编辑系统用户
export function sysUserEditor(params) {

  return post('/sysMain/editorSysUser', params);
}
//系统用户列表
export function userList(params) {

  return fetch('/sysMain/getSysUserList', params);
}

//禁用系统用户
export function userIsEnable(params) {

  return put('/sysMain/isEnable', params);
}

