<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-button type="primary" size="mini" style="float:left;margin:15px 15px 0px 0px;" icon="el-icon-plus" @click.native="isAddDialog = true;editType = ''">新增人员</el-button>
        <el-select class="more-select" size="mini" v-model="isDisable" placeholder="请选择人员状态">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" value="0"></el-option>
          <el-option label="禁用" value="1"></el-option>
        </el-select>
        <el-input style="width:150px;float:left;margin:15px 15px 0px 0px;" size="mini" placeholder="请输入名称搜索" v-model="search"></el-input>
        <el-button style="float:left;margin:15px 15px 0px 0px;" type="primary" size="mini" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
      </el-col>
      <el-col :span="4">
        <el-tree style="margin-top:20px;" :data="dataTree" :props="defaultProps" accordion @node-click="handleNodeClick" node-key="id" :default-expanded-keys="[orgId]"></el-tree>
      </el-col>
      <el-col :span="20">
        <el-table stripe :data="list" style="margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" height="calc(100vh - 200px)">
          <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
          <el-table-column prop="sysName" label="姓名" align="center">
            <template slot-scope="scope">
              <div class="goto-detail" @click="editDetail(scope.row.sysId, 1)">{{scope.row.sysName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="organizationName" align="center" label="所属部门">
          </el-table-column>
          <el-table-column prop="sysAccount" align="center" label="手机号/账号">
          </el-table-column>
          <el-table-column prop="roleName" align="center" label="角色">
          </el-table-column>
          <el-table-column prop="isDisable" align="center" label="状态" width="70">
            <template slot-scope="scope">
              <el-tag size="mini" :type="!scope.row.isEnable ? 'primary' : 'warning'" disable-transitions>{{!scope.row.isEnable ?'已启用':'已禁用'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="280">
            <template slot-scope="scope">
              <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row.sysId,2)">编辑</el-button>
              <el-button type="text" style="color:red" icon="el-icon-warning" v-if="sysId != scope.row.sysId" @click="resetPwd(scope.row.sysId)">重置密码</el-button>
              <el-button type="text" icon="el-icon-remove-outline" @click="setDisable(scope.row.sysId,scope.row.isEnable,scope.$index)" v-if="scope.row.isEnable == 0">禁用</el-button>
              <el-button type="text" icon="el-icon-remove-outline" @click="setDisable(scope.row.sysId,scope.row.isEnable,scope.$index)" v-if="scope.row.isEnable == 1">启用</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top:10px;" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog :destroy-on-close="true" :title="!editType ? '新增人员':editType == 1 ?'人员详情':'编辑人员信息'" :visible.sync="isAddDialog" :lock-scroll="false" :close-on-click-modal="false" width="520px">
      <Add v-if="isAddDialog" :isAddDialog.sync="isAddDialog" :editId.sync="editId" :editType.sync="editType" :pId="orgId"></Add>
    </el-dialog>
  </div>

</template>
<script>
import { getOrganizationTree, querySysUserListById, isEnable } from '@/api/setting'
import { resetPwd } from '@/api/user'
import Add from './components/add.vue'
import { getCookies } from '@/utils/utils'

export default {
  name: 'account',
  components: {
    Add
  },
  data() {
    return {
      // tree
      dataTree: [],
      defaultProps: {
        children: 'children',
        label: 'organizationName'
      },
      sysId: getCookies('sysId'),

      // Dialog
      isAddDialog: false,
      isDisable: '',           //状态
      editId: '',
      editType: '',            // 编辑状态 1是详情，2是编辑,''是添加
      // search
      search: '', //搜索
      organizationArea: '',
      moreSelectValue: '',
      // list
      orgId: getCookies('organizationId'),
      list: [
      ],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  watch: {
    isAddDialog(n, o) {
      if (!n) {
        this.orgId = getCookies('organizationId')
        this.getTreeList();
        this.getList();
      }
    },
  },
  created() {
    this.getList();
    this.getTreeList();
  },
  methods: {
    resetPwd(id) {
      let that = this;
      this.$confirm('此操作将重置该用户密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        resetPwd(id)
          .then((response) => {
            that.$message({
              message: response.msg || response.error_msg,
              type: response.code == 200 ? 'success' : 'default'
            });
          })
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    setDisable(id, isDisable, index) {
      let that = this;
      let param = {
        "id": id,
        "isEnable": Number(isDisable) == 1 ? 0 : 1
      }
      this.$confirm(Number(isDisable) == 1 ? '是否启用该人员?' : '是否禁用该人员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        isEnable(param)
          .then((response) => {
            if (response.code == 200) {
              this.getList();
            }
            that.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    handleNodeClick(data) {
      this.orgId = data.id;
      this.handleCurrentChange(1);
    },
    getTreeList() {
      let that = this;

      getOrganizationTree(this.orgId)
        .then((response) => {
          if (response.code == 200) {

            that.dataTree = [response.data];
            console.log(that.dataTree);
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }

        })
    },
    getList() {
      let param = {
        isPage: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: this.search,
        isEnable: this.isDisable,
        organizationId: this.orgId
      };
      querySysUserListById(param)
        .then((response) => {
          if (response.code == 200) {
            console.log(response.data.records);
            this.total = response.data.total;
            this.list = response.data.records;

          } else {
            this.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }

        })
    },
    clearSearch() {
      this.search = '';
      this.isDisable = '';
      this.organizationArea = '';
    },
    // type == 1 查看详情 type == 2 编辑
    editDetail(id, type) {
      this.editType = type;
      this.editId = id;
      this.isAddDialog = true;
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";

.add-btn {
  float: left;
  margin-right: 15px;
}
.more-select {
  margin-top: 15px;
  width: 10%;
  float: left;
  margin-right: 15px;
  min-width: 150px;
}
.btn-blue-hover {
  margin: 0 6px;
  color: $hoverRed;
}
.btn-blue-hover:hover {
  color: $hoverYellow;
}

.btn-red-hover {
  margin: 0 6px;
  color: $hoverBlue;
}
.btn-red-hover:hover {
  color: $hoverYellow;
}

.goto-detail {
  color: $hoverBlue;
}
.goto-detail:hover {
  cursor: pointer;
}
</style>